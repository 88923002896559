var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $contentErrors = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $emailError = $('.site-email-signup__message__error__email_address', $emailContainerNode);
        var $mobileTermsError = $('.site-email-signup__message__error__generic', $emailContainerNode);
        var $errorMessages = $('.error-message', $emailContainerNode);
        var $hideSMSPromo =
          typeof $emailContainerNode.data('hide-sms-promotion') == 'undefined'
            ? false
            : $emailContainerNode.data('hide-sms-promotion');
        var $showEmailPromo =
          typeof $emailContainerNode.data('show-email-promotion') == 'undefined'
            ? false
            : $emailContainerNode.data('show-email-promotion');
        var $emailTermsError;
        var $languageIdInput = $('input[name="LANGUAGE_ID"]', $emailContainerNode);
        var selectedLocale = generic.cookie('LOCALE');
        var languageSettings = Drupal.settings
          && Drupal.settings.common
          && Drupal.settings.common.translation_locales;
        var languageId = '';
        var localSuffix = 'fr.smashbox.ca';
        var frenchLocale = 'fr_CA';
        var srcUrl = window.top.document.location.href;
        var isCaFrLocale = selectedLocale === frenchLocale || srcUrl.indexOf(localSuffix) !== -1;

        // Updating the Language ID after the site is translated.
        if (isCaFrLocale && languageSettings) {
          languageId = languageSettings[frenchLocale];

          if (languageId) {
            $languageIdInput.val(languageId);
          }
        }

        if (!$hideSMSPromo) {
          var $mobileNumberInput = $('input[name="MOBILE_NUMBER"]', $emailContainerNode);
          var $mobileTermsAndConditions = $('.js-mobile-terms-conditions', $emailContainerNode);
          var $phoneError = $('.site-email-signup__message__error__mobile_phone', $emailContainerNode);
          var $mobileTermsLabel = $('.site-email-signup__checkbox__text', $emailContainerNode);
          var $mobilePrefixInput = $('input[name="MOBILE_PREFIX"]', $emailContainerNode);

          // Show terms + conditions
          $mobileNumberInput.on('focus', function (e) {
            $mobileTermsAndConditions.slideDown(300);
          });

          // hide terms + conditions when clicked outside
          $(document).click(function (hideEvt) {
            if (!$(hideEvt.target).closest($emailForm).length && !$(hideEvt.target).is($emailForm)) {
              if ($mobileTermsAndConditions.is(':visible')) {
                $mobileTermsAndConditions.slideUp(300);
              }
            }
          });
        }

        if ($showEmailPromo) {
          var $emailTermsAndConditions = $('.js-email-terms-conditions', $emailContainerNode);

          $emailTermsError = $('.site-email-signup__message__error__email_checkbox', $emailContainerNode);
          var $emailTermsLabel = $('.site-email-signup__checkbox__text', $emailContainerNode);

          // Show terms + conditions
          $emailInput.on('focus', function (e) {
            $emailTermsAndConditions.slideDown(300);
          });

          // hide terms + conditions when clicked outside
          $(document).click(function (hideEvt) {
            if (!$(hideEvt.target).closest($emailForm).length && !$(hideEvt.target).is($emailForm)) {
              if ($emailTermsAndConditions.is(':visible')) {
                $emailTermsAndConditions.slideUp(300);
              }
            }
          });
        }

        $emailForm.once('js-email-signup').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $errorMessages.addClass('hidden');
          $contentErrors.addClass('hidden');
          $emailSuccess.add($contentErrors).addClass('hidden');
          $emailInput.removeClass('error');
          $emailError.removeClass('error');

          if (!$hideSMSPromo) {
            $mobileTermsLabel.removeClass('error');
            $mobileNumberInput.removeClass('error');
            $mobilePrefixInput.removeClass('error');
          }
          if ($showEmailPromo) {
            $emailTermsLabel.removeClass('error');
          }

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          if (!$hideSMSPromo) {
            // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
            // we need a boolean value
            if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
              params.SMS_PROMOTIONS = 1;
            }
          }

          if ($showEmailPromo) {
            if ($emailContainerNode.find('input[name="PC_EMAIL_PROMOTIONS"]').prop('checked')) {
              params.PC_EMAIL_PROMOTIONS = 1;
            }
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
              } else {
                $(document).trigger('email_signup:success_and_new');
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                // Close active utility items
                $('.js-gnav-util__content').addClass('hidden');
                $emailInput.val('');
                if (!$hideSMSPromo) {
                  $mobileNumberInput.val('');
                  $mobileTermsAndConditions.slideUp(0);
                }
                if ($showEmailPromo) {
                  $emailInput.val('');
                  $emailTermsAndConditions.slideUp(0);
                }
                generic.overlay.launch({
                  html: $emailSuccess.html()
                });
              }
            },
            onFailure: function (jsonRpcResponse) {
              var errObjs = jsonRpcResponse.getMessages();

              for (var i = 0; i < errObjs.length; i++) {
                var myErr = errObjs[i];

                if (myErr && myErr.key) {
                  $contentErrors.removeClass('hidden');
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup'
                  ) {
                    $emailInput.addClass('error');
                    $emailError.removeClass('hidden');
                  }
                  if (!$hideSMSPromo) {
                    if (
                      myErr.key === 'required_dependency.sms_promotions.mobile_number.dependency.sms' ||
                      myErr.key.indexOf('.mobile_number.sms') > -1
                    ) {
                      $mobileNumberInput.addClass('error');
                      $mobilePrefixInput.addClass('error');
                      $phoneError.removeClass('hidden');
                    }
                    if (myErr.key === 'required_dependency.mobile_number.sms_promotions.dependency.sms') {
                      $mobileTermsLabel.addClass('error');
                      $mobileTermsError.removeClass('hidden');
                    }
                  }
                  if ($showEmailPromo) {
                    if (
                      myErr.key === 'required_dependency.pc_email_promotions.pc_email_address.dependency.email_signup'
                    ) {
                      $emailInput.addClass('error');
                      $emailError.removeClass('hidden');
                    }
                    if (
                      myErr.key.match(
                        'required_dependency.pc_email_address.pc_email_promotions.dependency.email_signup|required.pc_email_promotions.email_signup'
                      )
                    ) {
                      $emailTermsLabel.addClass('error');
                      $emailTermsError.html(myErr.text).removeClass('hidden');
                    }
                  }
                }
              }
            }
          });
        });
      });
    },
    gdprRemInfoMessageBox: function () {
      if ($('.info-msg-gdpr').get(0)) {
        $('.info-msg-gdpr').remove();
      }
    },

    gdprSetInfoBoxPosition: function (infoLink, $infoMessageBox, $infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt($infoMessageBox.css('max-width').replace('px', ''));

      if (isMobile) {
        $infoMsgArrow.css({
          left: infoLinkLeft - 10 + 'px'
        });
        infoLinkLeft = 10;
      } else if (
        $infoMessageBox.outerWidth(true) === infoMsgMaxWidth &&
        infoLinkLeft + infoMsgMaxWidth > screen.width
      ) {
        infoLinkLeft = infoLinkLeft - 480;
        $infoMsgArrow.addClass('top-right-arrow');
      }
      $infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      if (Drupal.settings.common.gdpr_compliant) {
        var isMobile = Unison.fetch.now().name === 'small';

        $('body', context).click(function (e) {
          if (e.target.className !== 'info-msg-gdpr' && e.target.className !== 'info-msg-gdpr__txt') {
            $('.info-msg-gdpr').addClass('hidden');
          }
        });
        $('.js-info-link-gdpr', context).live('click', function (e) {
          e.preventDefault();
          var infoLink = $(e.currentTarget);
          var $infoBoxEle;
          var $infoMessageBox;
          var $infoMsgArrow;

          site.emailSignup.gdprRemInfoMessageBox();
          $infoBoxEle = $('#' + infoLink.attr('message-in'));
          $(
            "<div class='info-msg-gdpr'><div class='info-msg-gdpr__arrow'></div><div class='info-msg-gdpr__txt js-info-msg-gdpr__txt'></div></div>"
          ).appendTo('body');
          $infoMessageBox = $('.info-msg-gdpr');
          $infoMsgArrow = $('.info-msg-gdpr__arrow');

          $('.info-msg-gdpr__txt').html(
            "<div class='info-msg-gdpr__close js-info-msg-gdpr__close'>X</div>" + $infoBoxEle.html()
          );

          $('.js-info-msg-gdpr__close', context).live('click', function (e) {
            e.stopPropagation();
            site.emailSignup.gdprRemInfoMessageBox();
          });

          site.emailSignup.gdprSetInfoBoxPosition(infoLink, $infoMessageBox, $infoMsgArrow, isMobile);
          $(window).on(
            'resize-debounced',
            site.emailSignup.gdprSetInfoBoxPosition(infoLink, $infoMessageBox, $infoMsgArrow, isMobile)
          );

          $('.js-info-msg-gdpr__txt a').live('mousedown', function (e) {
            // Reload only on left click
            if (e.which === 1) {
              window.location = $(this).attr('href');
            }
          });
        });
      }
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
      if (Drupal.settings.common.gdpr_compliant) {
        site.emailSignup.gdprToolTip(context);
      }
    }
  };
})(jQuery);
